<template>
	<div>
		<div>
			<v-row>
				<v-col>
					<a>Inicio</a>
				</v-col>
			</v-row>
			<v-row class="pt-3">
				<v-col cols="5">
					<h2 class="text-h4">Lista de Lecturas</h2>
				</v-col>
				<v-col cols="7">
					<ReadingsListFilter :filters="filters" :readings_watchmans_options="watchmans" />
				</v-col>
			</v-row>
			<span class="text-h3"><v-btn color="info" @click="exportToCSV">Descargar XLS</v-btn></span>
			<ReadingsList :readingsList="readingsFiltered" />
		</div>
	</div>
</template>

<script>
import ReadingsList from '../../components/ReadingsList.vue';
import ReadingsListFilter from '../../components/ReadingsListFilter.vue';
import { mapActions, mapState } from 'vuex';
export default {
	data() {
		return {
			filters: {
				channel: '',
				watchman: '',
			},
		};
	},
	computed: {
		...mapState({
			readings: state => state.readingControl.readings,
			watchmans: state => state.readingControl.watchmans,
		}),
		readingsFiltered() {
			let readingsFiltered = this.$utils
				.deepCopy(this.readings)
				.filter(reading => {
					const matchesChannel = reading.limnimeter?.channels.name
						.toLowerCase()
						.includes(this.filters.channel.toLowerCase());
					const matchesWatchman =
						this.filters.watchman === null ||
						this.filters.watchman === '' ||
						String(reading.association_user.id) ===
						this.filters.watchman;
					return matchesChannel && matchesWatchman;
				});
			return readingsFiltered;
		},
	},
	components: {
		ReadingsList,
		ReadingsListFilter,
	},
	methods: {
		...mapActions('readingControl', [
			'loadReadings',
			'watchmanReadings',
		]),
		convertToCSV(data) {
			// Obtener los encabezados de las columnas (keys del primer objeto)
			const headers = Object.keys(data[0]);

			// Convertir a CSV
			const rows = data.map(row => headers.map(fieldName => JSON.stringify(row[fieldName], null, '')).join(';'));

			// Unir encabezados y filas
			return [headers.join(';'), ...rows].join('\n');
		},
		downloadCSV(data, filename = 'lecturas.csv') {
			// Convertir la data a formato CSV
			const csv = this.convertToCSV(data);

			// Crear un Blob con los datos del CSV
			const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

			// Crear un enlace de descarga
			const link = document.createElement('a');
			const url = URL.createObjectURL(blob);
			link.setAttribute('href', url);
			link.setAttribute('download', filename);

			// Añadir el enlace al DOM, hacer clic y luego eliminarlo
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		},
		exportToCSV() {
			const readingsCSV = this.readingsFiltered.map((item) => {
				return {
					ID: item.id,
					Canal: item.limnimeter.name,
					Medicion: item.measurement,
					Celador: item.association_user.name,
				}
			})
			this.downloadCSV(readingsCSV);
		}

	},
	async mounted() {
		await this.loadReadings();
		await this.watchmanReadings();
	},
};
</script>
