<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-container grid-list-xs>
                    <v-row>
                        <v-col class="text-center">
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th v-for="(item, index) in headers" :key="index" class="text-left">
                                                {{ item.text }}
                                            </th>
                                            <th>Foto</th>
                                            <th>Opciones</th>
                                            <!-- <th>Ubicación</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in readingsShow" :key="index">
                                            <td v-for="(field, index) in headers" :key="`${index}-tbody`"
                                                class="text-left">
                                                <template>
                                                    {{ item[field.value] }}
                                                </template>
                                            </td>
                                            <td>
                                                <v-img :src=item.photoMeasurement width="100"></v-img>
                                            </td>
                                            <td class="text-left">Editar - Eliminar
                                            </td>
                                            <!-- <td class="text-left">
                                                <a href="https://www.google.cl/maps/@-35.7042614,-71.089944,16z?entry=ttu&g_ep=EgoyMDI1MDIwMy4wIKXMDSoASAFQAw%3D%3D"
                                                    target="_blank">Ver ubicación</a>
                                            </td> -->
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </div>


</template>


<script>

export default {
    name: 'readings-list',
    data() {
        return {
            headers: [
                { text: 'Canal', value: 'name' },
                { text: 'Lectura', value: 'measurementData' },
                { text: 'Caudal', value: 'caudal' },
                { text: 'Celador', value: 'user' },
                { text: 'Fecha', value: 'date' },
                { text: 'Incidencias', value: 'incidentsList' },
                { text: 'Observación', value: 'observation' }
            ],
        };
    },
    props: {
        readingsList: {
            type: Array,
            required: true
        }
    },
    computed: {
        readingsShow() {
            let readingsFiltered = this.$utils.deepCopy(this.readingsList).map((reading, index) => {
                reading.index = index
                reading.name = reading.limnimeter.channels.name
                reading.measurementData = reading.measurement + " cm"
                reading.user = reading.association_user.name
                const opciones = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
                reading.date = new Date(reading.createdAt).toLocaleDateString('es-ES', opciones)
                reading.photoMeasurement = process.env.VUE_APP_API + reading.photo[0].url
                reading.incidentsList = reading.incidents.map(incident => {
                    return incident.name
                })
                reading.caudal = parseFloat((reading.limnimeter?.formula?.coefficient * reading.measurement ** reading.limnimeter?.formula?.exponent).toFixed(2)) + " l/s"
                reading.incidentsList = reading.incidentsList.length > 0 ? reading.incidentsList.reduce((acc, curr) => acc + " - " + curr) : 'Sin incidencias'
                reading.observation = reading.observation.length > 0 ? reading.observation : 'Sin observacion'
                return reading
            })
            return readingsFiltered
        }
    },
}

</script>